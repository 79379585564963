import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from 'react-router-dom';
import {getCardMarketIdBySetCode} from "../../Services/getCardMarketIdBySetCode";
import './vendor.css';

let sumProfit = 0;
let sumCards = 0;
let autoGrindProgressIndex = undefined;

let basicLands = ['Island', 'Plains', 'Mountain', 'Swamp', 'Forest'];

const Vendor = () => {
    const { vendor } = useParams();
    const [grindableCards, setGrindableCards] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const buyRefs= useRef([]);
    const sellRefs= useRef([]);

    const fetchGrindableCards = () => {
        fetch("https://www.shreee2.de/getGrindables/"+vendor)
            .then(response => {
                return response.json()
            })
            .then(data => {
                setGrindableCards(data.results.cards);
                setVendorData(data.vendor);
                sumProfit = parseFloat(data.results.profitSum).toFixed(2);
                sumCards = parseFloat(data.results.cardsSum);
            })
    }

    useEffect(() => {
        fetchGrindableCards()
    }, [])

    return (
        <>
            <h1><Link
                    target={"_blank"}
                    to={'https://www.cardmarket.com/en/Magic/Users/'+vendor+'/Offers/Singles?idLanguage=1&condition=3'}
                >{vendor}</Link>
                &nbsp;<img width={'30'} height={'20'} alt={vendorData?.country} src={'/Assets/flags/'+vendorData?.country+'.svg'}></img>
            </h1>
            <div>

                <p>Sum profit: {sumProfit} € --- Cards: {sumCards}</p>
                <nav>
                    <button className={autoGrindProgressIndex >= 0 ? 'working' : 'not-working'} onClick={() => autoGrind(0)}>Grind</button>
                </nav>
                {grindableCards.length > 0 && (
                    <table>
                        <thead>
                        <tr>
                            <th>CK card name</th>
                            <th>Set</th>
                            <th>BUY</th>
                            <th>SELL</th>
                            <th>Cnd</th>
                            <th>QTY</th>
                            <th>Ratio</th>
                            <th>Profit</th>
                            <th>CardMarket</th>
                            <th>CardKingdom</th>
                        </tr>
                        </thead>
                        <tbody>
                        {grindableCards.map((card, i) => (
                            <tr key={card.key} className={autoGrindProgressIndex === i ? 'current' : ''}>
                                <td>{card.cardNameCK}{card.isFoil === '1' ? '*': ''}</td>
                                <td>
                                    <Link to={'https://www.scryfall.com/sets/'+card.setCode} target={"_blank"}>
                                        {card.setCode}
                                    </Link>
                                </td>
                                <td>{card.sellPrice} €</td>
                                <td>{card.buylistPrice} $</td>
                                <td>{card.sellCondition}</td>
                                <td>{calcQty(card)}</td>
                                <td>{parseFloat(card.grindRatio).toFixed(2)}</td>
                                <td>{parseFloat(card.profitPerCard*calcQty(card)).toFixed(2)}</td>
                                <td>
                                    <Link
                                        to={getBuyLinkCardMarket(vendor, card.baseName, card.key, calcQty(card),
                                            card.sellPrice, 0, card.isPlayset, card.setCode)}
                                        target={"_cardMarketBot"}
                                    >
                                        Card
                                    </Link>
                                    <span> </span>
                                    <Link
                                        to={getBuyLinkCardMarket(vendor, card.baseName, card.key, calcQty(card),
                                            card.sellPrice, 1, card.isPlayset, card.setCode)}
                                        target={"_cardMarketBot"}
                                        ref={el => (buyRefs.current[i] = el)}
                                        id={'buying'+i}
                                    >
                                        Buy
                                    </Link>
                                </td>
                                <td>
                                    <Link to={getSellLinkCardKingdom(vendor, card.cardNameCK, card.idCardKingdom, calcQty(card), 0)}
                                    target={"_cardKingdomBot"}>
                                        Card
                                    </Link>
                                    <span> </span>
                                    <Link
                                        to={getSellLinkCardKingdom(vendor, card.cardNameCK, card.idCardKingdom, calcQty(card), 1)}
                                        target={"_cardKingdomBot"}
                                        ref={el => (sellRefs.current[i] = el)}
                                        id={'selling'+i}
                                    >
                                        Sell
                                    </Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );

    function autoGrind(indexToClick) {
        buyRefs.current[indexToClick]?.click();
        sellRefs.current[indexToClick]?.click();

        for (let indexToClick = 0; indexToClick < grindableCards.length; indexToClick++) {
            setTimeout(() => {
                autoGrindProgressIndex = indexToClick;
                buyRefs.current[indexToClick]?.click();
                sellRefs.current[indexToClick]?.click();
                console.log("autoGrinding", indexToClick);
            }, 5000*indexToClick);
        }
    }
}

function getBuyLinkCardMarket(vendor, cardName, articleId, qty, sellPrice, autoMode, isPlayset = '0', setCode = '') {
    if (isPlayset === '1') {
        qty = Math.round(qty/4);
        sellPrice *= 4;
    }

    if (basicLands.includes(cardName.replace(/\s\(\d{1,3}\)/i, ''))) {

        return 'https://www.cardmarket.com/en/Magic/Users/'+vendor+'/Offers/Singles?idLanguage=1'+
            '&condition=3&autoModeBuying='+autoMode+'&articleId='+articleId+'&amountToBuy='+qty+'&idRarity=23'+
            '&maxPrice='+sellPrice+'&idExpansion='+getCardMarketIdBySetCode(setCode);
    }

    return 'https://www.cardmarket.com/en/Magic/Users/'+vendor+'/Offers/Singles?idLanguage=1&name='+cardName+
        '&condition=3&autoModeBuying='+autoMode+'&articleId='+articleId+'&amountToBuy='+qty+'&maxPrice='+sellPrice;
}

function getSellLinkCardKingdom(vendor, cardName, cardKingdomId, qty, autoMode) {
    return 'https://www.cardkingdom.com/purchasing/mtg_singles/?filter%5Bsort%5D=price_desc&filter%5Bsearch'+
        '%5D=mtg_advanced&filter%5Bname%5D='+cardName+'&sellerName='+vendor+'&productId='+cardKingdomId+
        "&qty="+qty+"&autoMode="+autoMode;
}

function calcQty(card) {
    return Math.min(card.buylistQuantity, card.sellQuantity)
}

export default Vendor;
