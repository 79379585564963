import logo from './logo.svg';
import './App.css';
import Vendors from "./Pages/Vendors/Vendors";
import Start from "./Pages/Start";
import Navigation from "./Components/Navigation"
import React, { useEffect, useState } from "react"
import {Route, Routes} from "react-router-dom";
import Vendor from "./Pages/Vendors/Vendor";

const baseUrl = "";

const App = () => {

    return (
        <>
            <Navigation/>
            <Routes>
                <Route path="/" element={<Start />} />
                <Route path="/vendors" element={<Vendors />} />
                <Route path="/vendors/:vendor" element={<Vendor />} />
            </Routes>
        </>

    );
}

export default App;
