import React, { useEffect, useState } from "react"
import {Link} from "react-router-dom";
import moment from "moment";
import useSWR from 'swr'

const Vendors = () => {
    /*const [vendors, setVendors] = useState([]);*/
    const fetcher = (...args) => fetch(...args).then(res => res.json())


/*    const fetchVendors = () => {
        fetch("https://www.shreee2.de/getAllVendors")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setVendors(data.results)
            })
    }

    useEffect(() => {
        fetchVendors()
    }, [])*/


    const { data, error, isLoading } =
        useSWR('https://www.shreee2.de/getAllVendors', fetcher,
            { refreshWhenHidden: true, refreshInterval: 60000, revalidateOnFocus: false })


    return (
        <>
            <h1>Vendors</h1>
            <div>
                {data && (
                    <table>
                        <thead>
                        <tr>
                            <th>Vendor name</th>
                            <th></th>
                            <th>Total cards</th>
                            <th>Potential buylist</th>
                            <th>Last update</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.map(vendor => (
                            <tr key={vendor.id}>
                                <td><Link target={'vendorTab'} to={`/vendors/${vendor.name}`}>{vendor.name}</Link></td>
                                <td><img width={'15'} height={'10'} alt={vendor.country} src={'/Assets/flags/'+vendor.country+'.svg'}></img></td>
                                <td>{vendor.cardsCount}</td>
                                <td>{parseFloat(vendor.potentialProfit).toFixed(2)} €</td>
                                <td>{calculateTimeToLastUpdate(vendor.lastUpdate)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
                {isLoading && (
                    <span>Loading..</span>
                )}
                {error && (
                    <span>Something went wrong. Sorry</span>
                )}
            </div>
        </>
    );
}

function calculateTimeToLastUpdate(string) {
    const lastUpdate = Date.parse(string);
    const now = Date.now();
    const timeDiff = (now - lastUpdate) / 120000;

    if (isNaN(lastUpdate)) {
        return "-";
    } else if (timeDiff <= 20) {
        return "..grinding..";
    } else {
        return moment(new Date(lastUpdate)).fromNow();
    }

}

export default Vendors;
