import React from "react";
import { Routes, Route } from 'react-router-dom';
import Vendors from "./Vendors/Vendors";




const Start = () => {


    return (
        <>
            <h1>Le grind</h1>
        </>
    );
}

export default Start;